
export function log() {
    let args = [];
    for (let arg of arguments) {
        args.push(JSON.parse(JSON.stringify(arg)));
    }

    console.log.apply(null, args);
}

export function getAlpineData(alpineData) {
    return JSON.parse(JSON.stringify(alpineData));
}
